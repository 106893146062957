@font-face {
    font-family: 'MyWebFont';
    src: url('../resources/Summit_OpenType/SummitBold-Inline.otf'); }

.name {
    text-align: center;
    font-family: 'MyWebFont';
    font-size: 400%;
    color: white;
}


