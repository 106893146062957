body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-position: center center;
  min-height: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: cover;
  -o-background-size: cover; 
  height: 100%;
}
/* 
.backgroundImage {
  background-image: url("./images/coffe_shop.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 100%;
  padding-bottom: 20px;
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Roboto', sans-serif;
  margin: 10px;
  text-align: center;
  color: white !important;
}

h6 {
  font-family: 'Roboto', sans-serif;
  margin: 10px;
  text-align: center;
  color: white !important;
}

h3 {
  font-family: 'Roboto', sans-serif;
  color: white !important;
  margin: '20px'; 
  margin-left: '150px';
  margin-right: '150px';
}

h2 {
  font-family: 'Roboto', sans-serif;
  color: white !important;
  margin: '20px'; 
  margin-left: '150px';
  margin-right: '150px';
}

h5 {
  margin-left: 150px;
  margin-right: 150px;
  padding: 55px 55px 55px 55px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  border-style: solid;
  border-radius: 25px;
  color: white !important;
}

.title {
  font-size: 400%;
  margin-bottom: 0;
}

.link {
  color: inherit;
}

ul {
  list-style: none; 
}

ul li::before {
  content: "\2022"; 
  color: white; 
  font-weight: bold; 
  display: inline-block; 
  width: 1em;
  margin-left: -1em; 
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  height: 15%;
  width: 15%;
}

iframe {
  margin: 0 auto;
  display: block;
}

.tales {
  width: 100%;
}
.carousel-inner img{
  width:100%;
  max-height: 1200px !important;
}
