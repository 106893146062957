.carousel {
    -webkit-transform: scale(0.67);
    -moz-transform: scale(0.67);
    transform: scale(0.67);
    margin: 0;
    width: auto;
    overflow: hidden;
}

.carousel .item img {
    max-height: 8px;
    min-width: auto;
  }